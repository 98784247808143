import {
  Box,
  Card,
  CircularProgress,
  Container, Grid, IconButton, InputAdornment, ListItem, ListItemAvatar, ListItemText, Stack, Tab, Tabs, TextField,
  Typography
} from '@mui/material';
import Page from '../components/Page';
import { AppDispatch, useAppSelector } from '../store';
import { useDispatch } from 'react-redux';
import _, { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import '@splidejs/react-splide/css';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import useToggle from '../hooks/useToggle';
import { getHelpSupportVideo, getHelpSupportVideoCategory } from '../store/HelpSupportVideo/api';
import { HelpSupportVideoItem } from '../store/HelpSupportVideo/api/interface';
import { AppColors } from '../utils/utils';
import SearchIcon from '../assets/social/search_member.svg';
import { inputTextFieldStyle } from '../utils/utilMethods';
import HelpSupportVideoModel from '../components/modal/HelpSupportVideoModel';
import { clearVideoList, updateSearchText } from '../store/HelpSupportVideo/helpSupportVideoSlice';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const HelpSupportVideo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { videoList, categoryList, isCategoryLoading, isLoading, searchText, hasMore, page } = useAppSelector((state) => state.helpSupportVideo);

  useEffect(() => {
    if (categoryList.length == 0 && !isCategoryLoading) {
      dispatch(getHelpSupportVideoCategory())
    }
  }, []);

  const {
    toggle: togglenHelpSupportVideo,
    onOpen: onOpenHelpSupportVideo,
    onClose: onCloseHelpSupportVideo,
  } = useToggle();


  const [activeTab, setActiveTab] = useState<number>(0);

  const [openHelpSupportVideoItem, setHelpSupportVideoItem] = useState<HelpSupportVideoItem | undefined>(undefined);


  const observer = useRef<IntersectionObserver>();


  const inputStyle = {
    borderRadius: 1,
    backgroundColor: '#FFFFFF',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleLoadMore = (isReload: boolean, searchKeyWord?: string) => {
    if (categoryList.length > 0) {
      if (isReload) {
        dispatch(clearVideoList({}));
      }

      dispatch(getHelpSupportVideo({ categortyId: categoryList[activeTab]?.id ?? 0, searchKeyword: (searchKeyWord ?? searchText), page: isReload ? 1 : (page + 1) }));
    }
  };


  useEffect(() => {
    if (searchText) {
      handleLoadMore(true);
    }
  }, [searchText])


  useEffect(() => {
    if (categoryList.length > 0) {
      handleLoadMore(true);
    }
  }, [categoryList, activeTab]);


  const lastElementRef = useCallback(
    // (*)
    (node: HTMLDivElement | null) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          handleLoadMore(false);
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, hasMore]
  );



  useEffect(() => {
    if (openHelpSupportVideoItem != undefined) {
      onOpenHelpSupportVideo();
    }
  }, [openHelpSupportVideoItem]);

  useEffect(() => {
    if (!togglenHelpSupportVideo) {
      setHelpSupportVideoItem(undefined);
    }
  }, [togglenHelpSupportVideo]);


  const searchHelpSupportVideo = (e: React.ChangeEvent<HTMLInputElement>) => {

    if ((e.target.value ?? "") == "" && videoList.length == 0) {
      handleLoadMore(true, "");
    }
    dispatch(updateSearchText(e.target.value ?? ""));


  };

  const debounceWithSearch = debounce(searchHelpSupportVideo, 700);

  const handleOnSupport = () => {
    navigate('/dashboard/support');
  }


  return (
    <>
      <Page
        title=' Help & Support | KnoCard'
        showAppBar={true}
      >
        <Container maxWidth='xl' sx={{ mt: { sm: 2, xs: 4 }, width: "100% !important", maxWidth: "none !important", marginLeft: "0px !important", marginRight: "0px !important" }}>

          <Box display='flex' alignItems='center' sx={{ mb: 2, }}>
            <TextField
              fullWidth
              key={'search-topic'}
              placeholder={"Search Topic"}
              InputProps={{
                sx: { ...inputTextFieldStyle(), borderRadius: '10px' },
                type: 'search',
                startAdornment: (<Fragment>
                  <Box component={'div'} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',

                  }}>
                    <Box
                      component={'img'}
                      src={SearchIcon}
                      alt='search icon'
                      sx={{
                        width: '30px',
                        height: '30px',
                        mr: 1,
                        color: AppColors.primaryColor,
                      }}
                    />
                  </Box>
                </Fragment >),
                // endAdornment: (
                //   isLoading ? (<CircularProgress size={28} sx={{ mr: 1 }} />) : (<></>)
                // ),
              }}
              defaultValue={searchText}
              onChange={debounceWithSearch}
            />
            <IconButton
              onClick={handleOnSupport}
              sx={{ ml: 1 }}
            >
              <SupportAgentIcon sx={{ color: AppColors.primaryColor }} />
            </IconButton>
          </Box>


          {!isCategoryLoading && (<Tabs value={activeTab} onChange={handleTabChange}
            variant='fullWidth'
            sx={{
              width: '100%',
              borderRadius: '10px',
              backgroundColor: AppColors.whiteColor,
              pl: { xs: 1, md: 0 },
              pr: { xs: 1, md: 0 },
            }}

            TabIndicatorProps={{ style: { backgroundColor: "transparent" } }} >
            {categoryList.map((item, idx) => {
              return (<Tab label={item.name ?? ""} key={`tutorial-tabs-${idx}`} sx={{
                color: AppColors.blackColor,
                fontWeight: '500',
                minHeight: '50px',
                '&.Mui-selected': {
                  fontWeight: '500',
                  backgroundColor: AppColors.primaryColor,
                  color: AppColors.whiteColor,
                  borderRadius: "10px"
                }
              }} />)
            })}
          </Tabs>)}

          {isCategoryLoading && (
            <Box display='flex' justifyContent='center' mt={2}>
              <CircularProgress />
            </Box>
          )
          }




          {videoList?.map((videoItem, idx) => {
            return (
              <><Card
                ref={
                  idx + 1 === videoList?.length
                    ? lastElementRef
                    : undefined
                }
                key={idx}
                sx={{
                  width: "100%",
                  boxShadow: 'unset !important',
                  p: 0,
                  mt: 1
                }}
                className='we-are-live-post-card'
              >
                <ListItem
                  onClick={() => setHelpSupportVideoItem(videoItem)}
                  alignItems="flex-start"

                >
                  <ListItemAvatar>
                    <Box
                      component={'img'}
                      loading="lazy"
                      borderRadius={'10px'}
                      src={`${videoItem.thumb_link ?? '/static/images/placeholder_profile.png'}`}
                      alt=""
                      sx={{
                        mr: 1.5,
                        width: { xs: '100px' },
                        height: { sm: '100px', xs: '50px' }
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Fragment>
                        <Typography
                          sx={{ display: 'inline', fontSize: 'medium', fontWeight: 500 }}
                          component="span"
                          color={AppColors.blackColor}
                        >
                          {videoItem?.title ?? ""}
                        </Typography>
                      </Fragment>
                    }
                    secondary={
                      <Fragment>
                        <Typography
                          sx={{ display: 'inline', fontSize: 'small', mt: 1 }}
                          component="span"
                          variant="body2"
                          color={AppColors.grayTwoColor}
                        >
                          {videoItem?.description ?? ""}
                        </Typography>
                      </Fragment>
                    }
                  />
                </ListItem>
              </Card></>
            );
          })}

          {isLoading && (
            <Box display='flex' justifyContent='center' mt={2}>
              <CircularProgress />
            </Box>
          )
          }


          {(!isLoading && videoList.length == 0 && !isCategoryLoading) && (
            <Box display='flex' justifyContent='center' mt={'20vh'}>
              <Typography>Video Not Found</Typography>
            </Box>
          )
          }
        </Container>
        {openHelpSupportVideoItem && <HelpSupportVideoModel
          key={`open-tutorial-video-model-${openHelpSupportVideoItem.id}`}
          onClose={onCloseHelpSupportVideo}
          toggle={togglenHelpSupportVideo}
          helpSupportVideoItem={openHelpSupportVideoItem}
        />}
      </Page >
    </>
  );
};


export default HelpSupportVideo;
