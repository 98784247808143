export interface Topic {
  status: { code: string; message: string };
  data: TopicData;
}

export interface SingleTopicResponse {
  status: { code: string; message: string };
  data: TopicObject;
}

export interface TopicData {
  current_page: number;
  data: TopicObject[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: null;
  path: string;
  per_page: number;
  prev_page_url: null;
  to: number;
  total: number;
}

export interface TopicObject {
  id: number;
  group_id: number;
  title: string;
  link: string;
  filename: string;
}

export interface TopicPagination {
  group_id: number;
  page: number;
}

interface InitialState {
  data: TopicData;
  status: string;
  hasMore: boolean;
  skip: number;
  currentPage: number;
  isError: boolean;
  isLoading: boolean;
  isSaving: boolean;
  isSavSuccess: boolean;
  isSuccess: boolean;
  message: string;
}

export const initialState: InitialState = {
  data: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 0,
    last_page: 0,
    last_page_url: '',
    next_page_url: null,
    path: '',
    per_page: 0,
    prev_page_url: null,
    to: 0,
    total: 0,
  },
  status: '',
  hasMore: false,
  skip: 0,
  currentPage: 1,
  isError: false,
  isLoading: false,
  isSaving: false,
  isSavSuccess: false,
  isSuccess: false,
  message: '',
};
