import moment from 'moment';

export const dateFormate = (date: string, format?: string) => {
  if (format) return moment(date).format(format);
  else return moment(date).format('ll');
};


export const formatVideoDuration = (durationInSeconds: number): string => {
  // Ensure the duration is a non-negative number
  if (durationInSeconds < 0) {
    throw new Error('Duration must be a positive number.');
  }

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = Math.floor(durationInSeconds % 60);

  // Format the output based on the time breakdown
  if (hours > 0) {
    // Format like "1:45 hr" (H:MM hr)
    return `${hours}:${minutes < 10 ? '0' + minutes : minutes} hr`;
  } else if (minutes > 0) {
    // Format like "45 min" (M min)
    return `${minutes} min`;
  } else {
    // Format like "19 sec" (S sec)
    return `${seconds} sec`;
  }
};