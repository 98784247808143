import { LoadingButton } from '@mui/lab';
import {
  Box,
  DialogActions,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { Transition } from '../Transition';
import { useAppDispatch, useAppSelector } from '../../store';
import React, { useCallback, useEffect, useRef } from 'react';
import { TopicObject } from '../../store/groupTrainingMediaTopic/api/interface';
import { AppColors } from '../../utils/utils';
import { incrementTopicCurrentPage } from '../../store/groupTrainingMediaTopic/groupTrainingMediaTopicSlice';
import { getTopicList } from '../../store/groupTrainingMediaTopic/api';


interface IProps {
  toggle: boolean;
  onClose: () => void;
  onTopicSelected: (topic: TopicObject) => void;
}

const AssignGroupTrainingMediaTopicModal: React.FC<IProps> = ({
  toggle,
  onClose,
  onTopicSelected,
}) => {

  const dispatch = useAppDispatch();
  const groupId = useAppSelector((state) => state?.auth?.user?.affiliate_group_id);
  const topicData = useAppSelector((state) => state?.groupTrainingMediaTopic.data?.data) ?? [];

  const isLoading = useAppSelector((state) => state?.groupTrainingMediaTopic.isLoading) ?? false;
  const hasMore = useAppSelector((state) => state?.groupTrainingMediaTopic.hasMore) ?? true;
  const currentPage = useAppSelector((state) => state?.groupTrainingMediaTopic.currentPage) ?? 0;
  const onCancel = () => {
    onClose();
  };
  const observer = useRef<IntersectionObserver>();

  useEffect(() => {
    if ((topicData?.length ?? 0) == 0) {
      dispatch(getTopicList({ page: 0, group_id: groupId ?? 0 }))
    }
  });


  const lastElementRef = useCallback(
    // (*)
    (node: HTMLDivElement | null) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          dispatch(incrementTopicCurrentPage(currentPage + 1));
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, hasMore]
  );

  useEffect(() => {

    if (!isLoading && hasMore) {
      dispatch(getTopicList({ page: currentPage, group_id: groupId ?? 0 }))
    }

  }, [currentPage]);


  return (
    <Dialog
      fullWidth
      open={toggle}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => { }}
      aria-describedby='alert-dialog-slide-description'
      sx={{
        zIndex: '5000',
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: AppColors.primaryColor,
          color: 'white',
        }}
      >
        Select Media Topic
      </DialogTitle>

      <DialogContent sx={{ pl: 0, pr: 0 }}>
        {topicData && topicData.length > 0 && (
          <List>
            {topicData.map((item, index) => (
              <Box ref={index + 1 === topicData?.length ? lastElementRef : undefined}>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      onTopicSelected(item);
                      onClose();
                    }}
                  >
                    <ListItemIcon>
                      <img
                        height='50px'
                        width='50px'
                        src={item.link}
                        alt={item.title}
                        style={{
                          borderRadius: 100,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={item.title}
                      sx={{ color: AppColors.grayThreeColor }}
                    />
                  </ListItemButton>
                </ListItem>
                <Divider
                  sx={{
                    mt: 0,
                    borderStyle: 'solid',
                    borderColor: AppColors.strokeColor,
                  }}
                />
              </Box>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions sx={{ paddingX: 3 }}>
        <LoadingButton
          type='button'
          loading={false}
          variant='contained'
          onClick={onCancel}
        >
          Cancel
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AssignGroupTrainingMediaTopicModal;
