import { STATUS_TYPE } from '../../../enum';
import { UserInfo } from '../../userInfo/api/interface';

export interface ShareActivityCountReponse {
  status: string;
  message?: string;
  data?: ShareActivityCount;
}

export interface ShareActivityCount {
  web_app_share_existing_contact?: number;
  web_app_share_new_contact?: number;
  landing_page_share_existing_contact?: number;
  landing_page_share_new_contact?: number;
  social_post_share_existing_contact?: number;
  social_post_share_new_contact?: number;
  review_request_share_existing_contact?: number;
  review_request_share_new_contact?: number;
  media_file_all_share_existing_contact?: number;
  media_file_all_share_new_contact?: number;
}


export interface SpecificGroupMediaShareActivityCountReponse {
  status: string;
  message?: string;
  data?: SpecificGroupMediaShareActivityCount;
}

export interface SpecificGroupMediaShareActivityCount {
  media_file_share_existing_contact?: number;
  media_file_share_new_contact?: number;
}

export interface ViewedActivityCountReponse {
  status: string;
  message?: string;
  data?: ViewedActivityCount;
}
export interface ViewedActivityCount {
  web_app_view_existing_contact?: number;
  web_app_view_new_contact?: number;
  landing_page_viewed_existing_contact?: number;
  landing_page_viewed_new_contact?: number;
  media_file_all_viewed_existing_contact?: number;
  media_file_all_viewed_new_contact?: number;
}



export interface SpecificGroupMediaViewedActivityCountReponse {
  status: string;
  message?: string;
  data?: SpecificGroupMediaViewedActivityCount;
}

export interface SpecificGroupMediaViewedActivityCount {
  media_file_viewed_existing_contact?: number;
  media_file_viewed_new_contact?: number;
}


export interface MediaFeedbackFormCountResponse {
  status: string;
  message?: string;
  data?: MediaFeedbackFormCount;
}

export interface MediaFeedbackFormCount {
  form_one_feedbacks?: MediaFeedbackFormCountItem[];
  form_two_feedbacks?: MediaFeedbackFormCountItem[];

}

export interface MediaFeedbackFormCountItem {
  form_answer?: string;
  total_answer?: number;
}

export interface CorporateGroupMediaVideoViewResponse {
  status: string;
  message?: string;
  data?: CorporateGroupMediaVideoViewItem[];
}

export interface CorporateGroupMediaVideoViewItem {
  content_title?: string;
  video_duration?: string;
  ten?: string;
  twenty?: string;
  thirty?: string;
  forty?: string;
  fifty?: string;
  sixty?: string;
  seventy?: string;
  eighty?: string;
  ninety?: string;
  hundred?: string;
}



export interface CorporateGroupLandingPageVideoViewResponse {
  status: string;
  message?: string;
  data?: CorporateGroupLandingPageVideoViewItem[];
}

export interface CorporateGroupLandingPageVideoViewItem {
  title?: string;
  video_duration?: string;
  ten?: string;
  twenty?: string;
  thirty?: string;
  forty?: string;
  fifty?: string;
  sixty?: string;
  seventy?: string;
  eighty?: string;
  ninety?: string;
  hundred?: string;
}


export interface GroupMediaResponse {
  status: string;
  message?: string;
  data?: GroupMediaItem[];
}

export interface GroupMediaItem {
  id: number;
  group_id?: number;
  topic_id?: number;
  description?: string;
  thumb_name?: string;
  thumb_link?: string;
  status?: number;
  is_included_group_share?: number;
  created_at?: string;
  updated_at?: string;
  contents?: GroupMediaItemContent[];
}

export interface GroupMediaItemContent {
  id?: number;
  post_id?: number;
  group_id?: number;
  user_id?: number;
  type?: string;
  link?: string;
  filename?: string;
  thumbnail?: string;
  thumb_filename?: string;
  title?: string;
  position?: number;
  description?: string;
  platform?: string;
  status?: number;
  created_at?: string;
  updated_at?: string;
}

export interface GroupMediaPostContent {
  file?: File;
  type: string;
  thumbnail?: File;
  title: string;
  description?: string;
  link?: string;
  thumbnail_link?: string | null;
  platform: string;
  content?: GroupMediaItemContent;
}

interface InitialState {
  groupMedia: GroupMediaItem[];
  corporateGroupMediaVideoView: CorporateGroupMediaVideoViewItem[];
  corporateGroupLandingPageVideoView: CorporateGroupLandingPageVideoViewItem[];
  shareActivityCount?: ShareActivityCount;
  specificGroupMediaShareActivityCount?: SpecificGroupMediaShareActivityCount;
  viewedActivityCount?: ViewedActivityCount;
  specificGroupMediaViewedActivityCount?: SpecificGroupMediaViewedActivityCount;
  mediaFeedbackFormCount?: MediaFeedbackFormCount;

  isShareActivityCountLoading: boolean;
  isSpecificGroupMediaShareActivityCountLoading: boolean;
  isViewedActivityCountLoading: boolean;
  isSpecificGroupMediaViewedActivityCountLoading: boolean;
  isMediaFeedbackFormCountLoading: boolean;
  isGroupMediaLoading: boolean;
  isCorporateGroupMediaVideoViewLoading: boolean;
  isCorporateGroupLandingPageVideoViewLoading: boolean;
}

export const initialState: InitialState = {
  groupMedia: [],
  corporateGroupMediaVideoView: [],
  corporateGroupLandingPageVideoView: [],
  shareActivityCount: undefined,
  specificGroupMediaShareActivityCount: undefined,
  viewedActivityCount: undefined,
  specificGroupMediaViewedActivityCount: undefined,
  mediaFeedbackFormCount: undefined,

  isShareActivityCountLoading: false,
  isSpecificGroupMediaShareActivityCountLoading: false,
  isViewedActivityCountLoading: false,
  isSpecificGroupMediaViewedActivityCountLoading: false,
  isMediaFeedbackFormCountLoading: false,
  isGroupMediaLoading: false,
  isCorporateGroupMediaVideoViewLoading: false,
  isCorporateGroupLandingPageVideoViewLoading: false
};
