import { LoadingButton } from '@mui/lab';
import {
  Box,
  DialogActions,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import LandscapeIcon from '@mui/icons-material/Landscape';
import { Transition } from '../Transition';
import { useAppDispatch, useAppSelector } from '../../store';
import React, { useEffect, useRef, useState } from 'react';
import { TopicObject } from '../../store/groupMediaTopic/api/interface';
import { FormikProvider, useFormik } from 'formik';
import topicSchema from '../../validation/topicSchema';
import {
  getCapitalizedString,
  inputTextFieldStyle,
  showErrorMessage,
  showSuccessMessage,
} from '../../utils/utilMethods';
import { acceptImageFileTypes } from '../../constant';
import Iconify from '../Iconify';

import { createEditTopic, deleteTopic } from '../../store/groupMediaTopic/api';
import {
  newTopicCreated,
  topicDeleted,
  topicUpdated,
} from '../../store/groupMediaTopic/groupMediaTopicSlice';
import { isMobile } from '../../utils/getMobileOperatingSystem';
import { AppColors } from '../../utils/utils';
import { color } from 'framer-motion';

interface IProps {
  groupId: number;
  toggle: boolean;
  onClose: () => void;
}

interface IInitialState {
  image: File | string | undefined;
  title: string;
}
const AddGroupMediaTopicModal: React.FC<IProps> = ({
  groupId,
  toggle,
  onClose,
}) => {
  const arrayHelpersRef = useRef<any>(null);
  const dispatch = useAppDispatch();

  const topicFileUploader = useRef<any>(null);

  const topicData =
    useAppSelector((state) => state?.groupMediaTopic.data?.data) ?? [];

  const [selectedTopic, setSelectedTopic] = useState<TopicObject | undefined>(
    undefined
  );

  const onCancel = () => {
    onClose();
  };

  const inputStyle = inputTextFieldStyle();

  useEffect(() => {
    if (toggle == true) {
      setSelectedTopic(undefined);
      formik.resetForm({
        values: {
          title: '',
          image: undefined,
        },
      });
    }
  }, [toggle]);

  useEffect(() => {
    formik.resetForm({
      values: {
        title: selectedTopic ? selectedTopic.title : '',
        image: selectedTopic ? selectedTopic.link : undefined,
      },
    });
  }, [selectedTopic]);

  const formik = useFormik<IInitialState>({
    initialValues: {
      image: undefined,
      title: '',
    },
    onSubmit: async (values) => {
      try {
        if (!selectedTopic && !values.image) {
          showErrorMessage('Topic image is required.');
        } else {
          var result = await dispatch(
            createEditTopic({
              groupId: groupId,
              topicId: selectedTopic?.id,
              title: values.title,
              image: values.image,
            })
          ).unwrap();
          if (result.data.data != null) {
            if (selectedTopic) {
              dispatch(topicUpdated(result.data.data));
              showSuccessMessage('Media topic updated successfully.');
            } else {
              dispatch(newTopicCreated(result.data.data));
              showSuccessMessage('Media topic created successfully.');
            }
            onCancel();
          }
        }
      } catch (error: any) {}
    },
    validationSchema: topicSchema,
  });

  function selectTopicImage() {
    topicFileUploader?.current?.click();
  }

  const onTopicFileChange = async (event: any) => {
    if (event.target.files.length > 0) {
      formik.setFieldValue('image', event.target.files[0]);
    }
  };

  const onDeleteTopicClicked = async (topicId: number) => {
    var result = await dispatch(
      deleteTopic({
        topicId: topicId,
      })
    ).unwrap();
    if (result.data.data != null) {
      dispatch(topicDeleted(result.data.data));
      showSuccessMessage('Media topic deleted successfully.');
      onCancel();
    }
  };

  return (
    <Dialog
      fullWidth
      open={toggle}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {}}
      aria-describedby='alert-dialog-slide-description'
      sx={{
        zIndex: '5000',
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: AppColors.primaryColor,
          color: 'white',
        }}
      >
        Topic Info
      </DialogTitle>
      <Divider
        sx={{
          mt: 0,
          borderColor: 'gray',
        }}
      />
      <Box
        sx={{
          background: AppColors.backgroundColor,
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <FormikProvider value={formik}>
            <DialogContent sx={{ padding: '20px' }}>
              <Stack
                divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />}
                spacing={3}
              >
                <Grid item xs={12} sm={12}>
                  <input
                    type='file'
                    id='file'
                    ref={topicFileUploader}
                    onClick={(e: any) => (e.currentTarget.value = '')}
                    onChange={onTopicFileChange}
                    style={{ display: 'none' }}
                    accept={[...acceptImageFileTypes].join(',')}
                  />

                  <Box
                    onClick={() => !formik.values.image && selectTopicImage()}
                    sx={{
                      mt: 1,
                      mb: 2,
                      borderRadius: '12px',
                      backgroundColor: AppColors.primaryColor,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                      cursor: 'pointer',
                      width: '100%',
                    }}
                  >
                    {formik.values.image && (
                      <Box
                        sx={{
                          width: '100%',
                          position: 'relative',
                          top: '10px',
                          right: '10px',
                        }}
                      >
                        <IconButton
                          aria-label='remove'
                          id='btn-remove'
                          aria-haspopup='true'
                          onClick={(event) => {
                            formik.setFieldValue('image', undefined);
                          }}
                          sx={{
                            right: '0px',
                            top: '0',
                            position: 'absolute',
                          }}
                        >
                          <CancelIcon sx={{ color: 'red' }} />
                        </IconButton>
                      </Box>
                    )}
                    {formik.values.image ? (
                      <Box
                        component={'img'}
                        src={
                          typeof formik.values.image == 'string'
                            ? formik.values.image
                            : URL.createObjectURL(formik.values.image)
                        }
                        alt='addMedia'
                        sx={{
                          maxWidth: '100%',
                          maxHeight: '200px',
                          padding: '10px',
                        }}
                      />
                    ) : (
                      <Stack
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          pt: 1,
                          pb: 1,
                        }}
                      >
                        <LandscapeIcon
                          sx={{ fontSize: '50px', color: AppColors.whiteColor }}
                        />
                        <Typography sx={{ color: AppColors.whiteColor }}>
                          Upload thumbnail{' '}
                        </Typography>
                      </Stack>
                    )}
                  </Box>
                  <Typography
                    sx={{ mt: 1, mb: 1, color: AppColors.grayThreeColor }}
                  >
                    Topic Name
                  </Typography>

                  <TextField
                    id='title'
                    name={`title`}
                    value={formik.values.title ?? ''}
                    onInput={(e: any) => {
                      e.target.value = getCapitalizedString(
                        e.target.value ?? ''
                      );
                    }}
                    onChange={formik.handleChange}
                    placeholder='Topic Name'
                    // placeholder='Topic Name'
                    fullWidth
                    inputProps={{
                      maxLength: 12,
                    }}
                    InputProps={{
                      sx: inputStyle,
                    }}
                    error={Boolean(formik.errors.title && formik.touched.title)}
                    helperText={
                      Boolean(formik.errors.title && formik.touched.title) &&
                      formik.errors.title
                    }
                  />

                  <Box
                    mt={3}
                    mb={2}
                    display={'flex'}
                    alignItems={'end'}
                    gap={0.5}
                  >
                    <Typography sx={{ mt: 1, color: AppColors.grayThreeColor }}>
                      Edit Topics
                    </Typography>
                    <Iconify
                      icon={'ic:baseline-edit'}
                      height={25}
                      width={25}
                      sx={{ color: AppColors.primaryColor }}
                    />
                  </Box>

                  {topicData && topicData.length > 0 && (
                    <Grid
                      container
                      spacing={1}
                      p={1}
                      sx={{
                        border: `1px solid ${AppColors.primaryColor}`,
                        borderRadius: 2,
                      }}
                    >
                      {topicData.map((item, index) => (
                        <Grid
                          item
                          md={3}
                          key={index}
                          display={'flex'}
                          flexDirection={'column'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          position={'relative'}
                          sx={{ cursor: 'pointer' }}
                        >
                          <img
                            // component='img'
                            height='50px'
                            width='50px'
                            src={item.link}
                            alt={item.title}
                            onClick={() => setSelectedTopic(topicData[index])}
                            style={{
                              borderRadius: 100,
                              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                              backgroundColor: 'white',
                            }}
                          />

                          <Typography
                            mt={1}
                            variant='body2'
                            textAlign={'center'}
                            sx={{ color: AppColors.grayThreeColor }}
                          >
                            {item.title}
                          </Typography>

                          <Box
                            onClick={() => onDeleteTopicClicked(item.id)}
                            sx={{
                              height: { xs: '10px', md: '40px' },
                              width: { xs: '10px', md: '40px' },
                              position: 'absolute',
                              top: '0',
                              right: '10px',
                            }}
                          >
                            <Iconify
                              icon={'zondicons:minus-solid'}
                              height={30}
                              width={30}
                              sx={{
                                color: AppColors.redColor,
                                backgroundColor: 'white',
                                borderRadius: 100,
                              }}
                            />
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Grid>
              </Stack>
            </DialogContent>
          </FormikProvider>
          <DialogActions sx={{ paddingX: 3 }}>
            <LoadingButton
              type='button'
              loading={false}
              variant='contained'
              onClick={onCancel}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              type='submit'
              loading={formik.isSubmitting}
              variant='contained'
            >
              {selectedTopic ? 'Save' : 'Add'}
            </LoadingButton>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
};

export default AddGroupMediaTopicModal;
