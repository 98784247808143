


export const FEATURES_LIST = [
  {
    key: 6,
    feature: 'Contact',
    lookupId: 6,
  },
  {
    key: 7,
    feature: 'Media',
    lookupId: 7,
  },
  {
    key: 9,
    feature: 'KnoCard Social',
    lookupId: 9,
  },
  {
    key: 10,
    feature: 'External Link',
    lookupId: 10,
  },
  {
    key: 12,
    feature: 'Preferred Partners',
    lookupId: 12,
  },
];
export const ITEM_DATA = [
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
  },
  {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
  },
  {
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
  },
  {
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
  },
  {
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
  },
  {
    img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    title: 'Mushrooms',
  },
];

export const LONG_STRING =
  'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Hic nulla recusandae quo eaque quidem commodi dolore neque cumque natus eum sed, id dolorum et aspernatur! Nihil quisquam explicabo nemo accusamus.';

// export const ICON_CODES = [
//     'E800',
//     'E801',
//     'E802',
//     'E803',
//     'E804',
//     'E805',
//     'E806',
//     'E807',
//     'E808',
//     'E809',
//     'E80a',
//     'E80b',
//     'E80c',
//     'E80d',
//     'E80e',
//     'E80f',
//     'E810',
//     'E811',
//     'E812',
//     'E813',
//     'E814',
//     'E815',
//     'E816',
//     'E817',
//     'E818',
//     'E819',
//     'E81a',
//     'E81b',
//     'E81c',
//     'E81d',
//     'E81e',
//     'E81f',
//     'E820',
//     'E821',
//     'E822',
//     'E823',
//     'E824',
//     'E825',
//     'E826',
//     'E827',
//     'E828',
//     'E829',
//     'E82a',
//     'E82b',
//     'E82c',
//     'E82d',
//     'E82e',
//     'E82f',
//     'E830',
//     'E831',
//     'E832',
//     'E833',
//     'E834',
//     'E835',
//     'E836',
//     'E837',
//     'E838',
//     'E839',
//     'E83a',
//     'E83b',
//     'E83c',
//     'E83d',
//     'E83e',
//     'E83f',
//     'E840',
//     'E841',
//     'E842',
//     'E843',
//     'E844',
//     'E845',
//     'E846',
//     'E847',
//     'E848',
//     'E849',
//     'E84a',
//     'E84b',
//     'E84c',
//     'E84d',
//     'E84e',
//     'E84f',
//     'E850',
//     'E851',
//     'E852',
//     'E853',
//     'E854',
//     'E855',
//     'E856',
//     'E857',
//     'E858',
//     'E859',
// ];

/*
  
  
export const FONT_ICON = [
    {fontFamily:"MyFlutterAppAll", code:"E800"},
    a_food_and_drink,
    a_food_court,
    a_food_delivery,
    a_food_restaurant_waiter,
    a_food_restaurant,
    a_foodaa_coffee_cup,
    a_foodab_bar,
    a_foodab_booking_reservation,
    aa_prof_auto_repair_mechanic,
    aa_prof_automobile,
    aa_prof_bt_security_guard,
    aa_prof_builder,
    aa_prof_business_woman,
    aa_prof_c_presentation_learning,
    aa_prof_computer_monitor,
    flower_bouquet,
    aa_prof_insurance_protection,
    aa_prof_law,
    aa_prof_loudspeaker,
    aa_prof_market_share,
    aa_prof_market2_growth,
    aa_prof_mic_microphone,
    aa_prof_nation_flag,
    aa_prof_network,
    aa_prof_paw,
    medicine_sign,
    aa_prof_re1,
    aa_prof_re2,
    aa_prof_re3,
    aa_prof_value,
    aa_prof_vphoto,
    aa_prof_vphoto2,
    aa_prof_women_cut_scissor,
    aa_prof_women_grooming_nail,
    aa_profz_cleaning,
    aa_profz_garden,
    aa_profz_garden2,
    aa_profz_lamp,
    aa_profz_maintenance,
    aa_profz_plumbing,
    aa_profz_saw,
    aa_z_prof_book_literature,
    aa_z_prof_gift_box,
    bldg_bank,
    bldg_cloth_store,
    bldg_prof_building,
    ent_culture_cinema,
    ent_dolphin,
    ent_gamepad,
    ent_horse,
    ent_movies,
    ent_music_note,
    ent_party_balloon,
    ent_playing_card_spade_shape,
    faith_pigeon_bird,
    fashion_beauty_cream,
    fashion_dress,
    fashion_female_hat,
    fashion_heel_sandal,
    fashion_z_lipstick,
    fit_apple_fruits,
    fit_champion_winner,
    fit_cycling,
    fit2,
    fitness_yoga_exercise,
    flower_bouquet,
    flower_rose_plant,
    flower_spa,
    med_ambulance,
    medab_brush_teeth,
    medical_briefcase,
    medicine_sign,
    shop_cost_tag,
    shop_online_card_payment,
    shopping_bag,
    shopping_cart,
    sm_facebook_round,
    sm_instagram,
    sm_thumbs_up_black,
    sm_twitter_round,
    sm_youtube_round,
    travel_business_trip,
    travel_earth,
    travel_plane,
    travel_ship,
    zz_baby_carriage,
    zz_binoculars,
    zz_heart_black,
    zz_mail,
    zz_planet_space,
    zz_read_book,
    zz_ring,
    {fontFamily:"ExternalAssetIcons", code:"E807"},
    {fontFamily:"ExternalAssetIcons",code:"E808"}
  ];

  */


export const FEEDBACK_FORM_ONE = [
  {
    index: 0,
    key: "product",
    title: "Our Products",
  },
  {
    index: 1,
    key: "opportunity",
    title: "Our Opportunity",
  },
  {
    index: 2,
    key: "both",
    title: "Both Product and Opportunity",
  },
  {
    index: 3,
    key: "not_at_this_time",
    title: "Nothing at this time",
  },
];

export const FEEDBACK_FORM_TWO = [
  {
    index: 0,
    key: "yes",
    title: "Yes",
  },
  {
    index: 1,
    key: "not_at_this_time",
    title: "Not at this time",
  },
];

export interface AnswerItem {
  index: number;
  key: string;
  title: string;
}


export const activityCards = [
  {
    _id: 1,
    title: 'New Prospects',
    code: 'lead',
    isPro: true
  },
  {
    _id: 2,
    title: 'Page Views',
    code: 'knocard',
    isPro: true
  },
  {
    _id: 3,
    title: 'Video Views',
    code: 'mediaVideoViewCount',
    isPro: true
  },
  {
    _id: 4,
    title: 'Media Shares',
    code: 'mediaShare',
    isPro: true
  },

  {
    _id: 5,
    title: 'Personal Shares',
    code: 'webAppShare',
    isPro: true
  },

  {
    _id: 6,
    title: 'Social Posts',
    code: 'shareSocialPosts',
    isPro: true
  },
  {
    _id: 7,
    title: 'Referrals',
    code: 'referrals',
    isPro: true
  },
  {
    _id: 8,
    title: 'Landing Page',
    code: 'group_landing_page',
    isPro: true
  },
];


export const youTubeVideoRegex =
  /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=|shorts\/))((\w|-){11})(?:\S+)?$/;
export const vimeoVideoRegex =
  /^(https?:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)(\/[a-zA-Z0-9]+)?$/;
export const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

export const acceptImageFileTypes = ['image/x-png', 'image/jpeg', 'image/jpg', 'image/peg'];
export const acceptVideoFileTypes = ['video/mp4'];

export const pdfFileTypes = ['application/pdf'];

export const audioFileTypes = ['audio/mp3'];


export function createArray(limit: number) {
  return [...Array(limit).keys()];
}


export const SOCIAL_LIKE_ICONS = ['/static/images/thumbup.png', '/static/images/smile.png', '/static/images/heart.png', '/static/images/support.png', '/static/images/idea.png', '/static/images/clap.png'];