import { createSlice } from '@reduxjs/toolkit';
import {
  changeAffiliateGroupBanner,
  findAffiliateGroup,
  findAffiliateGroupPricing,
  getAffiliateGroupMedia,
  toggleGroupShareStatus,
  updateActiveStatus,
} from './api';
import { initialState } from './api/interface';

const affiliateGroupSlice = createSlice({
  name: 'affiliateGroup',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload.status;
    },
    groupUpdated: (state, action) => {
      state.data.forEach((item: any, index: number) => {
        if (action.payload.id == item.id) {
          state.data[index] = action.payload;
        }
      });
    },
    newMediaCreated: (state, action) => {
      state.affiliateGroupMedia.unshift(action.payload);
    },
    mediaPostUpdated: (state, action) => {
      state.affiliateGroupMedia.forEach((item, index) => {
        if (action.payload.id == item.id) {
          state.affiliateGroupMedia[index] = action.payload;
        }
      });
    },
    postDeleted: (state, action) => {
      let array = [...state.affiliateGroupMedia];
      let position = -1;
      array.forEach((item, index) => {
        if (item.id == action.payload.id) {
          position = index;
        }
      });

      if (position !== -1) {
        array.splice(position, 1);
        state.affiliateGroupMedia = array;
      }
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(findAffiliateGroup.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(findAffiliateGroup.fulfilled, (state, action) => {
      if (action.payload.data.data != null) {
        state.data = [...action.payload.data.data];
      }
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(findAffiliateGroup.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });

    builder.addCase(findAffiliateGroupPricing.pending, (state, _) => {
      state.isPricingLoading = true;
    });
    builder.addCase(findAffiliateGroupPricing.fulfilled, (state, action) => {
      if (action.payload.data.data != null) {
        state.affiliateGroupPricing = [...action.payload.data.data];
      }
      state.isPricingLoading = false;
    });
    builder.addCase(findAffiliateGroupPricing.rejected, (state, _) => {
      state.isPricingLoading = false;
    });

    builder.addCase(changeAffiliateGroupBanner.pending, (state) => {});
    builder.addCase(changeAffiliateGroupBanner.fulfilled, (state, _) => {});
    builder.addCase(changeAffiliateGroupBanner.rejected, (state, action) => {});

    builder.addCase(getAffiliateGroupMedia.pending, (state) => {});
    builder.addCase(getAffiliateGroupMedia.fulfilled, (state, action) => {
      if (action.payload.data.data != null) {
        state.affiliateGroupMedia = [...action.payload.data.data];
      }
    });
    builder.addCase(getAffiliateGroupMedia.rejected, (state, action) => {});

    builder.addCase(updateActiveStatus.pending, (state) => {});
    builder.addCase(updateActiveStatus.fulfilled, (state, action) => {});
    builder.addCase(updateActiveStatus.rejected, (state, action) => {});

    builder.addCase(toggleGroupShareStatus.pending, (state) => {});
    builder.addCase(toggleGroupShareStatus.fulfilled, (state, action) => {});
    builder.addCase(toggleGroupShareStatus.rejected, (state, action) => {});
  },
});

export const {
  setStatus,
  reset,
  groupUpdated,
  newMediaCreated,
  mediaPostUpdated,
  postDeleted,
} = affiliateGroupSlice.actions;
export default affiliateGroupSlice.reducer;
