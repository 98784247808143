import { Box } from "@mui/material";

const RenderCovor: React.FC<{
  props: { type: string; value: string } | undefined;
  radius?: string | undefined;
}> = ({ props, radius }) => {
  if (props?.type === 'upload' || props?.type === 'asset' || props?.type === 'pattern') {
    return <Box component={'img'} src={props?.value} className='cover-img' sx={{ borderRadius: radius ?? "12px" }} />;
  } else if (props?.type === 'solid') {
    return (
      <Box sx={{ backgroundColor: props?.value, height: '100%', borderRadius: radius ?? "12px" }}></Box>
    );
  } else {
    //if (props?.type === 'gradient') {
    return (
      <Box
        sx={{
          backgroundImage: `linear-gradient(to ${props?.value == undefined ? 'bottom' : 'right'
            },${props?.value?.split(' ')[0] ?? '#1d92c8'}, ${props?.value?.split(' ')[1] ?? '#c1ddef'
            })`,
          height: '100%',
          borderRadius: radius ?? "12px"
        }}
      ></Box>
    );
  }
  return <></>;
};
export default RenderCovor;
