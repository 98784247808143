import * as Yup from 'yup';
import { MediaType } from '../enum';

const groupMediaContentSchema = Yup.object().shape({
  description: Yup.string().required('Description is required.'),
  items: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.string(),
        title: Yup.string().when('type', ([type], schema) => {
          if (type == MediaType.video) {
            return schema.required('Title is required.');
          }
          return schema;
        }),
      })
    )
    .required(),
});
export default groupMediaContentSchema;
