import { createSlice } from '@reduxjs/toolkit';
import { getTopicList } from './api';
import { initialState } from './api/interface';

const groupMediaTopicSlice = createSlice({
  name: 'groupMediaTopic',
  initialState,
  reducers: {
    newTopicCreated: (state, action) => {
      state.data.data.unshift(action.payload);
    },
    topicUpdated: (state, action) => {
      state.data.data.forEach((item, index) => {
        if (action.payload.id == item.id) {
          state.data.data[index] = action.payload;
        }
      });
    },
    topicDeleted: (state, action) => {
      let array = [...state.data.data];
      let position = -1;
      array.forEach((item, index) => {
        if (item.id == action.payload.id) {
          position = index;
        }
      });

      if (position !== -1) {
        array.splice(position, 1);
        state.data.data = array;
      }
    },
    incrementTopicCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getTopicList.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(getTopicList.fulfilled, (state, action) => {
      const posts = action.payload.data.data.data;
      if (action.payload.data.data.current_page == 1) {
        state.data.data = [];
      }
      state.data.data.push(...posts);
      state.message = action.payload.data.status.message;
      state.status = action.payload.data.status.code;
      state.data.current_page = action.payload.data.data.current_page;
      state.data.first_page_url = action.payload.data.data.first_page_url;
      state.data.from = action.payload.data.data.from;
      state.data.last_page = action.payload.data.data.last_page;
      state.data.last_page_url = action.payload.data.data.last_page_url;
      state.data.next_page_url = action.payload.data.data.next_page_url;
      state.data.path = action.payload.data.data.path;
      state.data.per_page = action.payload.data.data.per_page;
      state.data.prev_page_url = action.payload.data.data.prev_page_url;
      state.data.to = action.payload.data.data.to;
      state.data.total = action.payload.data.data.total;
      state.currentPage = action?.payload?.data?.data?.current_page || 0;
      state.hasMore = posts.length < 10 ? false : true;
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
    });
    builder.addCase(getTopicList.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });
  },
});

export const { newTopicCreated, topicUpdated, topicDeleted, reset, incrementTopicCurrentPage } =
  groupMediaTopicSlice.actions;
export default groupMediaTopicSlice.reducer;
