import { EngageInterestLevel, EngageLeadType } from "../enum";
import { AffiliateGroupPricing } from "../store/AffiliateGroup/api/interface";
import { CompanyFeed } from '../store/social/api/interface';
import { EngageActivity } from "../store/engagePipeline/api/interface";
import { UserInfo } from "../store/userInfo/api/interface";
import { AppColors, config } from "./utils";
import { toast } from "react-toastify";

export const getEngageLeadSource = (engageActivity: EngageActivity) => {
    switch (engageActivity.share_type) {
        case 'App\\Models\\SharePost':
            return 'Shared Media File';
        case 'App\\Models\\CompanyFeedShare':
            return 'Shared Social Post';
        case 'App\\Models\\ShareAffiliateLandingPage':
            if (engageActivity.share?.share_type == "network_landing_page") {
                return "Share Grow your virtual business";
            } else if (engageActivity.share?.share_type == "group_landing_page") {
                return "Share Group landing page";
            } else {
                return 'Share What is KnoCard';
            }
        case 'App\\Models\\RequestReview':
            return 'Request a Review';
        case 'App\\Models\\Share':
        case 'App\\Models\\ShareProfile':
            if (engageActivity?.target?.source_type == 'qr_form') {
                return 'QR Form';
            } else if (engageActivity?.target?.source_type == 'what_is_knocard') {
                return 'What is KnoCard';
            } else if (engageActivity?.target?.source_type == 'rejection_free') {
                return 'Rejection free';
            } else if (
                (engageActivity?.target?.documentUrl ?? '').trim().length > 0
            ) {
                return 'Scanned Business Card';
            } else if (engageActivity?.target?.is_saved_using_app == '1') {
                return 'Added as New Prospect';
            } else {
                return 'Shared KnoCard';
            }
    }
}

export const getCapitalizedString = (value: string) => {

    if (value.length > 0) {
        return capitalize("\n", capitalize(". ", value));
    }
    return value;
}


const capitalize = (splitChat: string, value: string) => {
    var stringArray = value.split(splitChat);

    stringArray.map((value, idx) => {
        try {
            let firstLetter = value.trimStart().charAt(0);
            stringArray[idx] = firstLetter.toUpperCase() + value.trimStart().slice(1);
        } catch (e) { }
    });
    return stringArray.join(splitChat);
}

export const getRandomIntNumber = (max: number): number => {
    return Math.floor(Math.random() * max);
}

export const getInitialInterestLevel = (interest_level?: string) => {
    switch (interest_level) {
        case EngageInterestLevel.HIGH:
            return EngageInterestLevel.HIGH.toString();
        case EngageInterestLevel.LOW:
            return EngageInterestLevel.LOW.toString();
        case EngageInterestLevel.MEDIUM:
            return EngageInterestLevel.MEDIUM.toString();
        case EngageInterestLevel.NO_INTEREST:
            return EngageInterestLevel.NO_INTEREST.toString();
        default:
            return "";
    }
}


export const getInitialEngageLeadType = (lead_type?: string) => {
    switch (lead_type) {
        case EngageLeadType.NEW_LEAD:
            return EngageLeadType.NEW_LEAD.toString();
        case EngageLeadType.NEW_PROSPECT:
            return EngageLeadType.NEW_PROSPECT.toString();
        default:
            return "";
    }
}


export const urlToFileObject = async (url: string, fileName: string) => {
    try {
        const response = await fetch(url);
        const blob = await response.blob();
        const file = new File([blob], fileName, { type: blob.type });
        return file;
    } catch (e) {

    }
    return null;
}




export const getSubscriptionPrice = (
  plan: string,
  isAnnually: boolean,
  affiliateGroupPricing?: AffiliateGroupPricing
) => {
  if (affiliateGroupPricing != null) {
    switch (plan) {
      case config.PRO_BASIC:
        if (isAnnually) {
          return Number(affiliateGroupPricing.basic_annual_price) || 0;
        } else {
          return Number(affiliateGroupPricing.basic_monthly_price) || 0;
        }
      case config.PRO_PRO:
        if (isAnnually) {
          return Number(affiliateGroupPricing.pro_annual_price) || 0;
        } else {
          return Number(affiliateGroupPricing.pro_monthly_price) || 0;
        }
      case config.PRO_PLUS:
        if (isAnnually) {
          return Number(affiliateGroupPricing.pro_plus_annual_price) || 0;
        } else {
          return Number(affiliateGroupPricing.pro_plus_monthly_price) || 0;
        }
      case config.PRO_1:
        return Number(affiliateGroupPricing.pro_one_price) || 0;
      case config.KNOCARD_LINK:
        if (isAnnually) {
          return 0;
        } else {
          return Number(affiliateGroupPricing.knocard_link_monthly_price) || 0;
        }
      case config.KNOCARD_SPOTLIGHT:
        // if (isAnnually) {
        //   return 0;
        // } else {
        //   return (
        //     Number(affiliateGroupPricing.knocard_spotlight_monthly_price) || 299
        //   );
        // }
        return (
          Number(affiliateGroupPricing.knocard_spotlight_monthly_price) || 299
        );
      case config.KNOCARD_LEADERS_CORNER:
        if (isAnnually) {
          return 0;
        } else {
          return (
            Number(
              affiliateGroupPricing.knocard_leaders_corner_monthly_price
            ) || 0
          );
        }

      default:
        return 0;
    }
  } else {
    switch (plan) {
      case config.PRO_BASIC:
        if (isAnnually) {
          return 100.0;
        } else {
          return 10.0;
        }
      case config.PRO_PRO:
        if (isAnnually) {
          return 350.0;
        } else {
          return 35.0;
        }
      case config.PRO_PLUS:
        if (isAnnually) {
          return 990.0;
        } else {
          return 99.0;
        }
      case config.PRO_1:
        return 499.0;
      case config.KNOCARD_LINK:
        if (isAnnually) {
          return 0;
        } else {
          return 15;
        }
      case config.KNOCARD_SPOTLIGHT:
        if (isAnnually) {
          return 0;
        } else {
          return 299;
        }
      case config.KNOCARD_LEADERS_CORNER:
        if (isAnnually) {
          return 0;
        } else {
          return 12;
        }
      default:
        return 0;
    }
  }
};

export const getPlanLabel = (plan: string) => {
  switch (plan) {
    case config.PRO_BASIC:
    case 'PRO_MONTH':
      return 'REFERRAL+';
    case config.PRO_PRO:
    case 'PRO_YEAR':
      return 'PRO';
    case config.PRO_PLUS:
      return 'PRO PLUS';
    case config.PRO_1:
      return '360';
    case config.PRO_FREE_TRIAL:
      return 'PRO FREE TRIAL';
    case config.KNOCARD_SOCIAL_CONNECT:
      return 'SOCIAL';
    case config.KNOCARD_LINK:
      return 'KNOCARD LINK';
    case config.KNOCARD_SPOTLIGHT:
      return 'KNOCARD SPOTLIGHT';
    case config.KNOCARD_LEADERS_CORNER:
      return 'KNOCARD LEADERS CORNER';
    default:
      return 'FREE';
  }
};



export const secondsToHMS = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor((seconds % 60) / 100);

    const hoursString = hours > 0 ? hours + ':' : '';
    const minutesString = (minutes < 10 ? '0' : '') + minutes + ':';
    const secondsString = (remainingSeconds < 10 ? '0' : '') + remainingSeconds;

    return hoursString + minutesString + secondsString;
}

export const shareSocialPostMessage = (postByUserName: string, shareCompanyFeed: CompanyFeed, shareBy?: UserInfo) => {
    return `check out this social post. ${config.VBC_URL}/${postByUserName}/community/${shareCompanyFeed.id}${shareBy != undefined && shareCompanyFeed?.company?.owner?.id != shareBy?.id ? `?sb=${shareBy?.referece_code}` : ""} Thought this might be of interest to you.`;
}

export const openLinkOnNewTab = (link: string) => {
    window.open(`${link}`, "_blank") || window.location.replace(`${link}`);
}


export const showSuccessMessage = (message: string) => {
    toast.success(message);
}

export const showErrorMessage = (message: string) => {
    toast.error(message);
}

export const formatReportCount = (data: number) => {
    const SI_SYMBOL = ["", "k", "m", "b", "t"];

    // what tier? (determines SI symbol)
    const tier = Math.log10(Math.abs(data)) / 3 | 0;

    // if zero, we don't need a suffix
    if (tier == 0) return data.toString();

    // get suffix and determine scale
    const suffix = SI_SYMBOL[tier];
    const scale = Math.pow(10, tier * 3);

    // scale the number
    const scaled = data / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
}


export const isLandingPageShareItem = (type: string) => {
    return ['affiliate_landing_page', 'rejection_free_landing_page', 'network_landing_page', 'group_landing_page'].includes(type);
}

export const cleanPhoneNumber = (phoneNumber: string) => {
    let cleanedNumber = phoneNumber.replace(/[-()]/g, '');
    return cleanedNumber;
}


export const calculateReportPercentageDiff = (valueOne: number, valueTwo: number): number => {
    var percentage = 0.0;

    if (valueOne == 0 && valueTwo == 0) {
        percentage = 0.0;
    } else if (valueOne <= 0) {
        percentage = 100.0;
    } else if (valueTwo <= 0) {
        percentage = -100.0;
    } else {
        percentage = ((valueTwo - valueOne) / valueOne) * 100;
    }
    return parseFloat(percentage.toFixed(1));
}


export const inputTextFieldStyle = () => {
    return {
        borderRadius: '4px',
        backgroundColor: AppColors.grayZeroColor,
        "& label": {
            color: AppColors.grayThreeColor
        },
        "& .MuiOutlinedInput-root input": {
            color: AppColors.grayThreeColor,
            "-webkit-text-fill-color": AppColors.grayThreeColor,
            borderWidth: '1px !important'
        },
        "& .MuiOutlinedInput-root fieldset": {
            "border-color": `${AppColors.strokeColor} !important`,
            borderWidth: '1px !important'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: `${AppColors.strokeColor} !important`,
                borderWidth: '1px !important'
            },
            '&:hover fieldset': {
                borderColor: `${AppColors.strokeColor} !important`,
                borderWidth: '1px !important'
            },
            '&.Mui-focused fieldset': {
                borderColor: `${AppColors.strokeColor} !important`,
                borderWidth: '1px !important'
            },
        },
        '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: `${AppColors.strokeColor} !important`,
        }
    };
};
