import { Box, Button, Card, Container, Grid, Typography } from '@mui/material';
import styles from '../styles/Reports.module.css';
import Page from '../components/Page';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, useAppSelector } from '../store';
import { isNotEmpty } from '../utils/valid';
import { useDropzone } from 'react-dropzone';
import Compressor from 'compressorjs';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AppColors } from '../utils/utils';
import {
  changeAffiliateGroupBanner,
  findAffiliateGroup,
} from '../store/AffiliateGroup/api';
import { AffiliateGroup } from '../store/AffiliateGroup/api/interface';
import { LoadingButton } from '@mui/lab';
import { showErrorMessage, showSuccessMessage } from '../utils/utilMethods';
import { groupUpdated } from '../store/AffiliateGroup/AffiliateGroupSlice';

const LeaderCorner = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { user } = useAppSelector((state) => state?.auth);

  const [profilePicture, setProfilePicture] = useState<File | undefined>(
    undefined
  );

  const [groupData, setGroupData] = useState<AffiliateGroup | undefined>(
    undefined
  );

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const { data: affiliateGroupList, isLoading: isAffiliateGroupLoading } =
    useAppSelector((state) => state?.affiliateGroup);

  useEffect(() => {
    if (isAffiliateGroupLoading === false && affiliateGroupList.length === 0) {
      dispatch(findAffiliateGroup());
    }
  }, []);

  useEffect(() => {
    if (affiliateGroupList.length > 0) {
      affiliateGroupList.forEach((item) => {
        if (item.id === user?.affiliate_group_id) {
          // `${item.owner_name}` === user?.username &&
          setGroupData(item);
        }
      });
    }
  }, [affiliateGroupList, user?.username]);

  const {
    getRootProps: profilePictureGetRootProps,
    getInputProps: profilePictureGetInputProps,
    open: profilePictureOpenDropZone,
    acceptedFiles: profilePictureAcceptedFiles,
  } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
  });

  useEffect(() => {
    if (isNotEmpty(profilePictureAcceptedFiles)) {
      const profilePicture = profilePictureAcceptedFiles[0];
      new Compressor(profilePicture, {
        quality: 0.6,
        success(compressedFile) {
          setProfilePicture(compressedFile as File);
        },
        error(error) {
          setProfilePicture(profilePicture);
        },
      });
    }
  }, [profilePictureAcceptedFiles]);

  const onSaveAffiliateGroupBannerClicked = async () => {
    setSubmitting(true);
    const bannerResponse = await dispatch(
      changeAffiliateGroupBanner({
        groupId: groupData!.id,
        vbcBackground: profilePicture!,
      })
    ).unwrap();

    if (bannerResponse.data && bannerResponse.data.status.code === 'success') {
      dispatch(groupUpdated(bannerResponse.data.group));
      showSuccessMessage('Group banner has been updated successfully.');
      setProfilePicture(undefined);
    } else if (bannerResponse?.data?.status?.description) {
      showErrorMessage(bannerResponse.data.status.description);
    }
    setSubmitting(false);
  };

  const handleOnGroupTrainingMediaClicked = () => {
    navigate('/dashboard/group-training-media');
  };
  
  const handleOnGroupMediaClicked = () => {
    navigate('/dashboard/group-media');
  };

  return (
    <>
      <Page title='Dashboard | KnoCard' showAppBar={true}>
        <Container maxWidth='xl'>
          <Box
            className={styles.mainContent}
            marginTop={5}
            sx={{ textAlign: 'center' }}
          >
            <Grid container>
              <Grid item sm={12} xs={12}>
                <Card
                  sx={{
                    width: '100%',
                    height: '50%',
                    position: 'relative',
                    borderRadius: '12px',
                  }}
                >
                  <Box
                    {...profilePictureGetRootProps({
                      className: 'dropzone',
                    })}
                  >
                    <input {...profilePictureGetInputProps()} />
                  </Box>

                  <Box
                    component={'img'}
                    // className='cover-img'
                    src={
                      profilePicture
                        ? URL.createObjectURL(profilePicture!)
                        : isNotEmpty(groupData?.background_link)
                        ? groupData?.background_link
                        : '/static/images/group_bg/knocard.jpg'
                    }
                    sx={{
                      borderRadius: '12px',
                      objectFit: 'cover',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      width: 'inherit',
                    }}
                  />

                  {`${groupData?.owner_name}` === user?.username && (
                    <Button
                      variant={'contained'}
                      sx={{
                        position: 'absolute',
                        bottom: 10,
                        right: 10,
                        textTransform: 'unset',
                        borderRadius: '10px',
                      }}
                      onClick={() => {
                        profilePictureOpenDropZone();
                      }}
                    >
                      <Typography>Edit Banner</Typography>
                    </Button>
                  )}
                </Card>

                {profilePicture && (
                  <Grid
                    xs={12}
                    justifyContent={'center'}
                    sx={{ textAlign: 'center', mb: 1, mt: 2 }}
                  >
                    <LoadingButton
                      //   fullWidth
                      type='submit'
                      variant='contained'
                      loading={isSubmitting}
                      onClick={onSaveAffiliateGroupBannerClicked}
                      sx={{
                        pl: 6,
                        pr: 6,
                        pt: 1,
                        pb: 1,
                        textTransform: 'unset',
                        fontSize: '1.0rem',
                      }}
                    >
                      Save
                    </LoadingButton>
                  </Grid>
                )}

                <Box>
                  <Button
                    variant='outlined'
                    sx={{
                      fontWeight: '500',
                      fontSize: '1.3rem',
                      textTransform: 'capitalize',
                      marginTop: '20px',
                      color: AppColors.whiteColor,
                      background: AppColors.primaryColor,
                      borderRadius: '12px',
                      minWidth: { xs: '90%', md: '25%' },
                    }}
                    endIcon={
                      `${groupData?.owner_name}` === user?.username ? (
                        <AddCircleIcon
                          sx={{
                            color: AppColors.whiteColor,
                            float: 'right',
                          }}
                        />
                      ) : null
                    }
                    onClick={handleOnGroupTrainingMediaClicked}
                  >
                    {`${groupData?.owner_name}` === user?.username
                      ? 'Add Leaders Training'
                      : 'Team Training'}
                  </Button>
                </Box>

                {groupData?.owner_name === user?.username && (
                  <Box>
                    <Button
                      variant='outlined'
                      sx={{
                        fontWeight: '500',
                        fontSize: '1.3rem',
                        textTransform: 'capitalize',
                        marginTop: '20px',
                        color: AppColors.whiteColor,
                        background: AppColors.primaryColor,
                        borderRadius: '12px',
                        minWidth: { xs: '90%', md: '25%' },
                      }}
                      endIcon={
                        `${groupData?.owner_name}` === user?.username ? (
                          <AddCircleIcon
                            sx={{
                              color: AppColors.whiteColor,
                              float: 'right',
                            }}
                          />
                        ) : null
                      }
                      onClick={handleOnGroupMediaClicked}
                    >
                      {`${groupData?.owner_name}` === user?.username
                        ? 'Add Group Media'
                        : 'Group Media'}
                    </Button>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Page>
    </>
  );
};
export default LeaderCorner;
