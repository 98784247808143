import { Box, Button, Card, Grid, TextField, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Stack } from '@mui/system';
import LandscapeIcon from '@mui/icons-material/Landscape';
import { Transition } from '../Transition';
import React, { useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import {
  getCapitalizedString,
  inputTextFieldStyle,
} from '../../utils/utilMethods';
import { AppColors, ThumbnailTextColors } from '../../utils/utils';
import { splitString } from '../../utils/splitString';
import RenderCovor from '../VbcComponents/RenderCover';
import BackgroundSelectionView from '../BackgroundSelectionView';

interface IProps {
  thumbImage: File | string | undefined;
  text: string | undefined;
  textColor: string | undefined;
  toggle: boolean;
  onClose: () => void;
  onThumbnailTileChanged: (
    thumb: File | string | undefined,
    text: string | undefined,
    textColor: string | undefined
  ) => void;
}

interface IInitialState {
  text: string | undefined;
  textColor: string | undefined;
}

const AddMediaThumbnailTileWizardModal: React.FC<IProps> = ({
  thumbImage,
  text,
  textColor,
  toggle,
  onClose,
  onThumbnailTileChanged,
}) => {
  const [backgroundImage, setBackgroundImage] = useState<
    File | string | undefined
  >(thumbImage);
  const [coverType, setCoverType] = useState(splitString(''));

  useEffect(() => {
    if (backgroundImage != null) {
      if (typeof backgroundImage === 'string') {
        setCoverType(splitString(backgroundImage));
      } else {
        setCoverType(
          splitString(`0|upload|${URL.createObjectURL(backgroundImage)}`)
        );
      }
    }
  }, [backgroundImage]);

  const formik = useFormik<IInitialState>({
    initialValues: {
      text: text,
      textColor: textColor,
    },
    // validationSchema: youtubeLinkPostSchema,
    onSubmit: async (values) => {
      try {
        onThumbnailTileChanged(
          backgroundImage,
          values.text ?? text,
          values.textColor ?? textColor
        );
        onCancel();
      } catch (error: any) {}
    },
  });

  const onCancel = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <Dialog
      fullWidth
      open={toggle}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        formik.resetForm();
      }}
      aria-describedby='alert-dialog-slide-description'
      PaperProps={{
        sx: {
          background: 'none',
          boxShadow: 'none',
          p: '2%',
        },
      }}
      sx={{
        zIndex: '5000',
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <FormikProvider value={formik}>
          <DialogContent
            sx={{
              backgroundColor: AppColors.primaryColor,
              position: 'relative',
              minHeight: '50vh',
              borderRadius: '20px',
            }}
          >
            <Box
              component={'img'}
              src='/static/images/color_paint.png'
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                opacity: 0.5,
                maxHeight: '80px',
              }}
            />
            <Stack spacing={3}>
              <Grid item xs={12} sm={12}>
                <Typography sx={{ color: AppColors.blackColor, mb: 0.5 }}>
                  Step 1
                </Typography>
                <Box p={1}>
                  <Typography
                    variant='h6'
                    sx={{ color: AppColors.whiteColor, mb: 0.5 }}
                  >
                    Choose a Style
                  </Typography>
                  <BackgroundSelectionView
                    setBackgroundImage={(
                      background: File | string | undefined
                    ) => {
                      setBackgroundImage(background);
                    }}
                  />
                </Box>
                <Typography sx={{ color: AppColors.blackColor }}>
                  Step 2
                </Typography>
                <Box p={1}>
                  <Typography
                    variant='h6'
                    sx={{ color: AppColors.whiteColor, mb: 0.5 }}
                  >
                    Add Title
                  </Typography>
                  <TextField
                    id='text'
                    name={`text`}
                    value={formik.values.text ?? text}
                    inputProps={{
                      maxLength: 50, // Setting the max length for the input
                    }}
                    InputProps={{
                      sx: inputTextFieldStyle(),
                    }}
                    onInput={(e: any) => {
                      e.target.value = getCapitalizedString(
                        e.target.value ?? ''
                      );
                    }}
                    onChange={formik.handleChange}
                    placeholder='Add Text'
                    fullWidth
                    variant='outlined'
                    error={Boolean(formik.errors.text && formik.touched.text)}
                    helperText={
                      Boolean(formik.errors.text && formik.touched.text) &&
                      formik.errors.text
                    }
                  />
                  <Grid
                    container
                    mt={1}
                    flexDirection={'row'}
                    justifyContent={'right'}
                    gap={1}
                  >
                    {ThumbnailTextColors.map((clrItem) => {
                      return (
                        <>
                          <Grid
                            item
                            sx={{
                              backgroundColor: clrItem,
                              height: '35px',
                              width: '35px',
                              borderRadius: '8px',
                              border:
                                (formik.values.textColor ?? textColor) ==
                                clrItem
                                  ? `1px solid ${AppColors.orangeColor}`
                                  : undefined,
                            }}
                            onClick={() => {
                              formik.setFieldValue('textColor', clrItem);
                            }}
                          ></Grid>
                        </>
                      );
                    })}
                  </Grid>
                  {backgroundImage ? (
                    <Card
                      sx={{
                        mt: 2,
                        width: '100%',
                        height: '200px',
                        position: 'relative',
                        borderRadius: '12px',
                      }}
                    >
                      <RenderCovor props={coverType} />

                      <Typography
                        variant='h6'
                        sx={{
                          color: formik.values.textColor ?? textColor,
                          position: 'absolute',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          alignSelf: 'center',
                          textAlign: 'center',
                        }}
                      >
                        {formik.values.text ?? text}
                      </Typography>
                    </Card>
                  ) : (
                    <Card
                      sx={{
                        mt: 2,
                        width: '100%',
                        height: '200px',

                        borderRadius: '12px',
                        pt: 1,
                        pb: 1,
                      }}
                    >
                      <Stack
                        sx={{
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <LandscapeIcon
                          sx={{
                            fontSize: '70px',
                            color: AppColors.grayOneColor,
                          }}
                        />
                        <Typography
                          variant='h6'
                          sx={{ color: AppColors.grayOneColor }}
                        >
                          Tile Preview
                        </Typography>
                      </Stack>
                    </Card>
                  )}
                </Box>
              </Grid>
            </Stack>
            <Stack
              flexDirection={'row'}
              justifyContent={'right'}
              gap={2}
              mt={1}
            >
              <Button
                type='button'
                variant={'contained'}
                sx={{
                  textTransform: 'unset',
                  backgroundColor: 'white',
                  color: AppColors.primaryColor,
                  ':hover': {
                    color: AppColors.whiteColor,
                  },
                }}
                onClick={onCancel}
              >
                <Typography>Cancel</Typography>
              </Button>
              <Button
                type='submit'
                variant='contained'
                sx={{
                  textTransform: 'unset',
                  backgroundColor: 'white',
                  color: AppColors.primaryColor,
                  ':hover': {
                    color: AppColors.whiteColor,
                  },
                }}
              >
                <Typography>Save</Typography>
              </Button>
            </Stack>
          </DialogContent>
        </FormikProvider>
      </form>
    </Dialog>
  );
};

export default AddMediaThumbnailTileWizardModal;
