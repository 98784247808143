import { configureStore, AnyAction, combineReducers } from '@reduxjs/toolkit';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';
import userInfoReducer from './userInfo/userInfoSlice';
import companyFeedReducer from './companyFeed/companyFeedSlice';
import preferredPartnerSlice from './preferredPartner/preferredPartnerSlice';
import postReducer from './post/postSlice';
import mediaTopicReducer from './mediaTopic/mediaTopicSlice';
import companyExternalLinksReducer from './companyExternalLinks/companyExternalLinksSlice';
import communityFeedReducer from './communityFeed/communityFeedSlice';
import authReducer from './auth/authSlice';
import registerReducer from './register/registerSlice';
import affiliateGroupReducer from './AffiliateGroup/AffiliateGroupSlice';
import selectAffiliateGroupStateReducer from './selectAffiliateGroupState/selectAffiliateGroupStateSlice';
import mediaUploadReducer from './mediaUpload/mediaUploadSlice';
import purchaseReducer from './purchase/purchaseSlice';
import communityUsers from './communityUsers/communityUsersSlice';
import socialPost from './social/socialSlice';
import reports from './reports/reportsSlice';
import pricingVideo from './pricingVideo/pricingVideoSlice';
import contacts from './contacts/contactsSlice';
import chat from './chat/chatSlice';
import engagePipeline from './engagePipeline/engagePipelineSlice';
import engage from './engage/engageSlice';
import notification from './notification/notificationSlice';
import tutorialVideo from './tutorialVideo/tutorialVideoSlice';
import trainingVideo from './trainingVideo/trainingVideoSlice';
import affiliateLandingPageVideo from './affiliateLandingPageVideo/affiliateLandingPageVideoSlice';
import networkLandingPageVideo from './networkLandingPageVideo/networkLandingPageVideoSlice';
import rejectionFreePageVideo from './rejectionFreePageVideo/rejectionFreePageVideoSlice';
import mediaLibrary from './mediaLibrary/mediaLibrarySlice';
import knoCardLink from './knocardLink/knocardLinkSlice';
import knoCardLinkSession from './knocardLinkSession/knocardLinkSessionSlice'
import knocardLinkSessionNote from './knocardLinkSessionNote/knocardLinkSessionNoteSlice';
import reviewForm from './reviewForm/reviewFormSlice'
import courseVideo from './courseVideo/courseVideoSlice';
import customLandingPage from './customLandingpage/customLandingPageSlice';
import corporateReporting from './corporateReporting/corporateReportingSlice';
import helpSupportVideo from './HelpSupportVideo/helpSupportVideoSlice';
import shareHistory from './ShareHistory/shareHistorySlice';
import prospectActivity from './ProspectActivity/prospectActivitySlice';
import groupMediaTopic from './groupMediaTopic/groupMediaTopicSlice';
import groupTrainingMediaTopic from './groupTrainingMediaTopic/groupTrainingMediaTopicSlice';
import groupTrainingPost from './groupTrainingPost/groupTrainingPostSlice';

const reducers = {
  userInfo: userInfoReducer,
  companyFeed: companyFeedReducer,
  preferredPartner: preferredPartnerSlice,
  post: postReducer,
  mediaTopic: mediaTopicReducer,
  companyExternalLinks: companyExternalLinksReducer,
  communityFeed: communityFeedReducer,
  auth: authReducer,
  register: registerReducer,
  affiliateGroup: affiliateGroupReducer,
  selectAffiliateGroupState: selectAffiliateGroupStateReducer,
  mediaUpload: mediaUploadReducer,
  purchase: purchaseReducer,
  communityUsers: communityUsers,
  socialPost: socialPost,
  reports: reports,
  pricingVideo: pricingVideo,
  contacts: contacts,
  chat: chat,
  engagePipline: engagePipeline,
  engage: engage,
  notification: notification,
  tutorialVideo: tutorialVideo,
  trainingVideo: trainingVideo,
  affiliateLandingPageVideo: affiliateLandingPageVideo,
  networkLandingPageVideo: networkLandingPageVideo,
  mediaLibrary: mediaLibrary,
  rejectionFreePageVideo: rejectionFreePageVideo,
  knoCardLink: knoCardLink,
  knoCardLinkSession: knoCardLinkSession,
  knocardLinkSessionNote: knocardLinkSessionNote,
  reviewForm: reviewForm,
  courseVideo: courseVideo,
  customLandingPage: customLandingPage,
  corporateReporting: corporateReporting,
  helpSupportVideo: helpSupportVideo,
  shareHistory: shareHistory,
  prospectActivity: prospectActivity,
  groupMediaTopic: groupMediaTopic,
  groupTrainingMediaTopic: groupTrainingMediaTopic,
  groupTrainingPost: groupTrainingPost,
};

export const rootReducer = combineReducers(reducers);

const resettableRootReducer = (
  state: ReturnType<typeof rootReducer> | undefined | any,
  action: AnyAction
) => {

  if (action.type === 'auth/logoutUser/fulfilled') {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

export const store = configureStore({
  reducer: resettableRootReducer,
});

export default store;

type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();




