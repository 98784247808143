import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../client';
import { SingleTopicResponse, Topic, TopicPagination } from './interface';

export const getTopicList = createAsyncThunk(
  'mediaTopic/getTopicList',
  async ({ group_id, page }: TopicPagination, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<Topic>(
        `/api/group-training-media-topic?group_id=${group_id}&page=${page}`
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getTopicData = createAsyncThunk(
  'mediaTopic/getTopicData',
  async ({ topicId }: { topicId: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<SingleTopicResponse>(
        `/api/group-training-media-topic/${topicId}`
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const createEditTopic = createAsyncThunk(
  'mediaTopic/createEditTopic',
  async (
    {
      groupId,
      topicId,
      title,
      image,
    }: { groupId: number; topicId?: number; title: string; image?: File | string },
    { rejectWithValue }
  ) => {
    try {
      const request = new FormData();
      request.append('group_id', groupId.toString());
      request.append('title', title);
      if (image && typeof image !== 'string') {
        request.append(`image`, image);
      }

      var url = 'api/group-training-media-topic/';
      if (topicId) {
        url += `edit/${topicId}`;
      } else {
        url += `create`;
      }

      const response = await axiosInstance.post<SingleTopicResponse>(
        url,
        request,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteTopic = createAsyncThunk(
  'mediaTopic/deleteTopic',
  async ({ topicId }: { topicId: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete<SingleTopicResponse>(
        `api/group-training-media-topic/${topicId}`
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
