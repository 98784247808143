import {
  Box,
  Button,
  Checkbox,
  CheckboxProps,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import '@splidejs/react-splide/css';
import { AppColors, config } from '../../utils/utils';
import { setItem } from '../../utils/localStorage';
import { useAppSelector } from '../../store';
import { isMobile } from '../../utils/getMobileOperatingSystem';
import { useState } from 'react';
import styled from '@emotion/styled';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SquareIcon from '@mui/icons-material/Square';

type IProps = {
  toggle: boolean;
  onClose: () => void;
};

const opts = {
  height: '100%',
  width: '100%',
};

const WhiteCheckbox = styled(Checkbox)<CheckboxProps>(({ theme }) => ({
  color: 'red',
  '& .MuiIconButton-label': {
    position: 'relative',
    zIndex: 0,
  },
  '&:not($checked) .MuiIconButton-label:after': {
    content: '""',
    left: 4,
    top: 4,
    height: 15,
    width: 15,
    position: 'absolute',
    backgroundColor: 'white',
    zIndex: -1,
  },

  checked: {},
}));

const DownloadAppModel: React.FC<IProps> = ({ toggle, onClose }) => {
  const { user } = useAppSelector((state) => state?.auth);

  const [dontShowAgain, setDontShowAgain] = useState<boolean>(false);

  const handleCloseTapped = () => {
    onClose();
  };

  const handleDontShowAgain = () => {
    setItem(config.DOWNLOAD_APP_DONT_SHOW_AGAIN_AFTER_SIGN_UP, user?.username);
    onClose();
  };

  return (
    <>
      <Dialog
        open={toggle}
        onClose={() => {
          onClose();
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='lg'
        fullScreen={isMobile() ? true : false}
        PaperProps={{
          sx: {
            background: 'none',
            boxShadow: 'none',
            fontFamily: 'Lato',
          },
        }}
        sx={{
          zIndex: 3000,
        }}
      >
        <>
          <DialogContent
            sx={{
              // backgroundImage: 'linear-gradient( #0382FC, #66DAFC)',
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
              position: 'relative',
              p: '10%',
            }}
          >
            <Box
              component={'img'}
              src='/static/images/knocard_badge_logo.png'
              sx={{
                height: 'auto',
                width: '20%',
                right: '15%',
                top: '15%',
                transform: 'translate(50%, -50%)',
                position: 'absolute',
              }}
            />
            <Grid
              container
              p={'5%'}
              display='flex'
              direction={'column'}
              // justifyContent={'center'}
              // alignItems={'center'}
              sx={{
                width: isMobile() ? '100%' : '40vw',
                backgroundImage: 'linear-gradient( #0382FC, #66DAFC)',
                borderRadius: '30px',
              }}
            >
              <Grid item pl={'10%'}>
                <Typography
                  component='p'
                  variant='h5'
                  sx={{
                    color: AppColors.yellowColor,
                    fontWeight: 800,
                    fontFamily: 'Lato',
                  }}
                >
                  Important message
                </Typography>
              </Grid>
              <Grid item mt={1}>
                <Typography
                  component='p'
                  variant='h4'
                  sx={{
                    color: AppColors.whiteColor,
                    fontWeight: 800,
                    fontFamily: 'Lato',
                  }}
                >
                  Welcome to KnoCard
                </Typography>
              </Grid>
              <Grid item mt={2}>
                <Typography
                  component={'span'}
                  className='download-app-text-black'
                  sx={{
                    fontWeight: 600,
                    fontFamily: 'Lato',
                  }}
                >
                  You are currently{' '}
                </Typography>
                <Typography
                  component={'span'}
                  className='download-app-text-white'
                  sx={{
                    fontWeight: 600,
                    fontFamily: 'Lato',
                  }}
                >
                  logged into KnoCard.com. Please download our mobile app for
                  the{' '}
                </Typography>
                <Typography
                  component={'span'}
                  className='download-app-text-black'
                  sx={{
                    fontWeight: 600,
                    fontFamily: 'Lato',
                  }}
                >
                  Full{' '}
                </Typography>
                <Typography
                  component={'span'}
                  className='download-app-text-white'
                  sx={{
                    fontWeight: 600,
                    fontFamily: 'Lato',
                  }}
                >
                  experience of KnoCard. You will{' '}
                </Typography>
                <Typography
                  component={'span'}
                  className='download-app-text-black'
                  sx={{
                    fontWeight: 600,
                    fontFamily: 'Lato',
                  }}
                >
                  ONLY{' '}
                </Typography>
                <Typography
                  component={'span'}
                  className='download-app-text-white'
                  sx={{
                    fontWeight: 600,
                    fontFamily: 'Lato',
                  }}
                >
                  be able to Share via text message from our mobile app. Please
                  download today!
                </Typography>
              </Grid>
              <Grid
                item
                display='flex'
                alignItems='center'
                justifyContent='center'
                mt={2}
              >
                <a
                  href={config.APP_STORE_URL}
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <img
                    src='/static/images/app_store_image.png'
                    height={window?.innerWidth > 500 ? '55%' : '80%'}
                    width={window?.innerWidth > 500 ? '55%' : '80%'}
                    alt='apple-link'
                  />
                </a>

                <a
                  href={config.PLAY_STORE_URL}
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginLeft: '10px',
                  }}
                >
                  <img
                    src='/static/images/google_playstore_image.png'
                    height={window?.innerWidth > 500 ? '55%' : '80%'}
                    width={window?.innerWidth > 500 ? '55%' : '80%'}
                    alt='google-link'
                  />
                </a>
              </Grid>
              <Grid item mt={1.5}>
                <Typography
                  component='p'
                  textAlign={'center'}
                  sx={{
                    color: 'black',
                    fontWeight: 600,
                    fontFamily: 'Lato',
                  }}
                >
                  At login, use your same username and password
                </Typography>
              </Grid>
              <Grid item mt={1} textAlign={'center'}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={dontShowAgain}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setDontShowAgain(event.target.checked)
                      }
                      icon={<SquareIcon style={{ fill: 'white' }} />}
                      sx={{
                        fontFamily: 'Lato',
                        color: 'black',
                        '&.Mui-checked': {
                          color: 'white',
                        },
                      }}
                    />
                  }
                  sx={{
                    color: 'black',
                    fontWeight: 600,
                    fontFamily: 'Lato',
                    '&:span': {
                      fontSize: '0.9rem',
                      fontFamily: 'Lato',
                    },
                    '& .MuiTypography-root': {
                      fontFamily: 'Lato',
                    },
                  }}
                  label="Don't show this again"
                />
              </Grid>
              <Grid item display={'flex'} justifyContent={'center'} mt={1}>
                <Button
                  variant='contained'
                  onClick={
                    dontShowAgain ? handleDontShowAgain : handleCloseTapped
                  }
                  // endIcon={<CancelIcon sx={{ color: AppColors.redColor }} />}
                  sx={{
                    color: AppColors.whiteColor,
                    textTransform: 'unset',
                    borderRadius: '12px',
                  }}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </>
      </Dialog>
    </>
  );
};

export default DownloadAppModel;
