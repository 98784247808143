import { useEffect, useState } from 'react';
import { Box, Grid, Tab, Tabs, Typography, useTheme } from '@mui/material';
import Compressor from 'compressorjs';
import { pattern, solid, gradient } from '../utils/splitString';
import { useDropzone } from 'react-dropzone';
import { isNotEmpty } from '../utils/valid';
import BackgroundMediaLibraryView from './imagelibrary/BackgroundMediaLibraryView';
import { MediaLibraryItem } from '../store/mediaLibrary/api/interface';
import { AppColors } from '../utils/utils';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

interface IProps {
  setBackgroundImage: (background: File | string | undefined) => void;
}
const BackgroundSelectionView: React.FC<IProps> = ({ setBackgroundImage }) => {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const {
    getRootProps: backgroundImageGetRootProps,
    getInputProps: backgroundImageGetInputProps,
    open: backgroundImageOpenDropZone,
    acceptedFiles: backgroundImageAcceptedFiles,
  } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
  });

  useEffect(() => {
    if (isNotEmpty(backgroundImageAcceptedFiles)) {
      onBackgroundSelected(backgroundImageAcceptedFiles[0]);
    }
  }, [backgroundImageAcceptedFiles]);

  const backgroundIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onBackgroundSelected = (background: File | string) => {
    if (typeof background === 'string') {
      setBackgroundImage(background);
    } else {
      new Compressor(background, {
        quality: 0.6,
        success(compressedFile) {
          setBackgroundImage(compressedFile as File);
        },
        error(error) {
          setBackgroundImage(background);
        },
      });
    }
  };

  const getSolidBackgrounds = () => {
    return backgroundIds.map((id) => {
      return (
        <Grid
          key={`bg-${id}`}
          item
          //margin={0.5}

          xs={4}
          md={4}
          onClick={() => {
            onBackgroundSelected(`${id}|solid`);
          }}
          // width={{ xs: '45px', md: '70px' }}
          height={{ xs: '30px', md: '45px' }}
          className='padding-3'
        >
          <Box
            sx={{
              backgroundColor: `${solid(id.toString()).value}`,
              height: '100%',
              width: '100%',
            }}
          ></Box>
        </Grid>
      );
    });
  };

  const getGradientBackgrounds = () => {
    return backgroundIds.map((id) => {
      const _gradient = gradient(id.toString());
      return (
        <Grid
          key={`gbg-${id}`}
          item
          //margin={0.5}
          onClick={() => {
            onBackgroundSelected(`${id}|gradient`);
          }}
          xs={4}
          md={4}
          //width={{ xs: '45px', md: '70px' }}
          height={{ xs: '30px', md: '45px' }}
          className='padding-3'
        >
          <Box
            sx={{
              backgroundImage: `linear-gradient(to ${
                _gradient.value == undefined ? 'bottom' : 'right'
              },${_gradient?.value?.split(' ')[0] ?? '#1d92c8'}, ${
                _gradient?.value?.split(' ')[1] ?? '#c1ddef'
              })`,
              height: '100%',
              width: '100%',
            }}
          ></Box>
        </Grid>
      );
    });
  };

  const getPatternBackgrounds = () => {
    return backgroundIds.map((id) => {
      const _pattern = pattern(id.toString());
      return (
        <Grid
          key={`pbg-${id}`}
          item
          // margin={0.5}
          onClick={() => {
            onBackgroundSelected(`${id}|pattern`);
          }}
          xs={4}
          md={4}
          // width={{ xs: '45px', md: '70px' }}
          height={{ xs: '33px', md: '50px' }}
          className='padding-3'
        >
          <Box
            sx={{
              backgroundImage: `url(${_pattern.value})`,
              height: '100%',
              width: '100%',
            }}
          ></Box>
        </Grid>
      );
    });
  };

  const handleOnMediaSelectFromMediaLibrary = async (
    mediaLibraryItem: MediaLibraryItem
  ) => {
    if (mediaLibraryItem.link != null && mediaLibraryItem.filename != null) {
      onBackgroundSelected(`0|asset|${mediaLibraryItem.link}`);
    }
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          sx={{
            backgroundColor: AppColors.primaryColor,
            color: 'white',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            pl: 0,
            pr: 0,
            pt: 0.5,
            pb: 0.5,
            width: '100%',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            // indicatorColor='secondary'

            variant={'fullWidth'}
            scrollButtons={'auto'}
            allowScrollButtonsMobile
            sx={{
              pl: 2,
              pr: 2,
            }}
            TabIndicatorProps={{
              style: {
                backgroundColor: 'transparent',
              },
            }}
          >
            <Tab
              label='Solid'
              sx={{
                background: value == 0 ? AppColors.grayZeroColor : 'unset',
                color: AppColors.whiteColor,
                borderRadius: '12px',
              }}
              {...a11yProps(0)}
            />
            <Tab
              label='Gradient'
              sx={{
                background: value == 1 ? AppColors.grayZeroColor : 'unset',
                color: AppColors.whiteColor,
                borderRadius: '12px',
              }}
              {...a11yProps(1)}
            />
            <Tab
              label='Pattern'
              sx={{
                background: value == 2 ? AppColors.grayZeroColor : 'unset',
                color: AppColors.whiteColor,
                borderRadius: '12px',
              }}
              {...a11yProps(2)}
            />
            {/* <Tab
              label='Image Library'
              sx={{
                background: value == 3 ? AppColors.grayZeroColor : 'unset',
                color: AppColors.whiteColor,
                borderRadius: '12px',
              }}
              {...a11yProps(3)}
            /> */}
            <Tab
              label='Upload'
              sx={{
                background: value == 3 ? AppColors.grayZeroColor : 'unset',
                color: AppColors.whiteColor,
                borderRadius: '12px',
              }}
              {...a11yProps(3)}
            />
          </Tabs>
        </Grid>

        <Grid item sx={{ padding: 0, width: '100%' }}>
          <Box sx={{ bgcolor: AppColors.backgroundColor, padding: 0 }}>
            {/* <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      > */}

            <TabPanel value={value} index={0} dir={theme.direction}>
              <Grid
                container
                direction={'row'}
                justifyContent={'center'}
                sx={{ padding: 0 }}
              >
                {getSolidBackgrounds()}
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <Grid
                container
                direction={'row'}
                justifyContent={'center'}
                sx={{ padding: 0 }}
              >
                {getGradientBackgrounds()}
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <Grid
                container
                direction={'row'}
                justifyContent={'center'}
                sx={{ padding: 0 }}
              >
                {getPatternBackgrounds()}
              </Grid>
            </TabPanel>

            {/* <TabPanel value={value} index={3} dir={theme.direction}>
              <BackgroundMediaLibraryView
                onSelectMedia={handleOnMediaSelectFromMediaLibrary}
              />
            </TabPanel> */}
            <TabPanel value={value} index={3} dir={theme.direction}>
              <div
                {...backgroundImageGetRootProps({
                  className: 'dropzone',
                })}
              >
                <input {...backgroundImageGetInputProps()} />
                <Typography
                  variant='h6'
                  color={'primary'}
                  textAlign={'center'}
                  onClick={() => {
                    backgroundImageOpenDropZone();
                  }}
                  p={'64px'}
                >
                  Upload Thumbnail
                </Typography>
              </div>
            </TabPanel>

            {/* </SwipeableViews> */}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default BackgroundSelectionView;
